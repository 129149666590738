<template>
  <div id="delete-item-dialog">
    <el-dialog
      width="450px"
      :visible="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :title="`Delete ${name}?`"
    >
      <div class="delete-warning-message">
        Are you sure you want to permanently delete <b>{{ name }}</b>?
      </div>

      <div class="delete-instructions">
        Type <b>{{ name }}</b> into the box below to continue.
      </div>

      <div>
        <el-input
          v-model="inputValue"
          :placeholder="name"
        />
      </div>

      <span slot="footer">
        <el-button @click="close()">
          Cancel
        </el-button>
        <el-button
          type="danger"
          :disabled="!enableDeleteButton"
          @click="submit()"
        >
          Delete
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * DeleteItemDialog
 *
 * Prompt user to confirm the deletion of an item.
 *
 * Events:
 *
 * @close: trigger on dialog close.
 * @delete: trigger on confirmation of item deletion.
 */

export default {
  name: 'DeleteItemDialog',
  props: {
    name: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      inputValue: ''
    }
  },
  computed: {
    enableDeleteButton () {
      return this.inputValue === this.name
    }
  },
  methods: {
    /**
     * Reset component state and emit close signal.
     */
    close () {
      this.inputValue = ''
      this.$emit('close')
    },
    /**
     * Validate form data, close dialog, and emit delete signal.
     */
    submit () {
      this.close()
      this.$emit('delete')
    }
  }
}
</script>

<style>
#delete-item-dialog .el-dialog__body {
  padding: 20px;
}
</style>

<style lang="scss" scoped>
.delete-warning-message {
  margin-bottom: 20px;
}
.delete-instructions {
  margin-bottom: 10px;
}
</style>
