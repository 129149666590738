<template>
  <div id="property-edit">
    <property-form
      v-if="property"
      ref="form"
      :data="property"
      :company="company"
      :loading="loading"
      @submit="handleSubmit"
    >
      <template #title>
        <div class="info">
          <h1>{{ propertyName }}</h1>
          <p>{{ company.human_name }}</p>
        </div>
      </template>
      <template #buttons>
        <el-button
          v-if="canDeleteProperty"
          id="delete-property-btn"
          size="medium"
          @click="showDeletePropertyDialog = true"
        >
          Delete Property
        </el-button>
      </template>
    </property-form>

    <delete-item-dialog
      v-if="property"
      :name="property.property.name"
      :show="showDeletePropertyDialog"
      @close="showDeletePropertyDialog = false"
      @delete="deleteProperty"
    />
  </div>
</template>

<script>
import BasePropertyForm from './_components/BasePropertyForm'
import DeleteItemDialog from '@/components/dialogs/DeleteItemDialog'
import RooofAPI from '@/services/api/rooof'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'PropertyEdit',
  components: {
    'property-form': BasePropertyForm,
    'delete-item-dialog': DeleteItemDialog
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      property: undefined, // use default prop value while fetching property
      propertyName: 'loading',
      showBillingHistoryDialog: false,
      showDeletePropertyDialog: false,
      failedSubscriptions: []
    }
  },
  computed: {
    /**
     * Determines if the property can be deleted.
     * It can be deleted if the property has no product subscriptions or
     * are all pending.
     * We can't rely on the property status since pending subscriptions
     * take precedence over inactive.
     */
    canDeleteProperty () {
      if (!this.property || !this.property.property.product_subscriptions) {
        return false
      }
      return this.property.property.product_subscriptions.every((subscription) => {
        return subscription.status === 'pending'
      })
    }
  },
  created () {
    this.getProperty()
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id !== from.params.id) {
      this.getProperty()
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs.form && this.$refs.form.dataModified) {
      const answer = window.confirm('Form data has been modified without saving. Continue?')
      if (!answer) {
        next(false)
        return
      }
    }
    this.$store.commit('CLEAR', 'selectedProperty')
    next()
  },
  methods: {
    /**
     * Fetch the CraigslisProperty object from the API.
     */
    async getProperty () {
      try {
        this.loading = true
        const response = await CraigslistAPI.properties.retrieve(this.$route.params.id)
        this.property = response
        this.propertyName = response.property.name
        this.$store.commit('SET', ['selectedProperty', JSON.parse(JSON.stringify(response))])
      } catch (err) {
        if (err.response && err.response.status === 404) {
          this.$alert(`Property ${this.$route.params.id} doesn't seem to exist`, {
            title: 'Property not found',
            type: 'error'
          })
          this.$router.push({ name: 'CompanyOverview' })
        } else {
          const details = err.response ? err.response.data : null
          this.$rfAlert.error(this, err.toString(), details)
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Callback handler for submit event.
     */
    handleSubmit (formData) {
      this.updateProperty(formData)
    },
    /**
     * Create request to update the property objects.
     */
    async updateProperty () {
      this.loading = true
      try {
        const promises = []
        const propertyId = this.property.property.id
        const photos = this.property.photos

        if (photos) {
          for (const photo of photos.added) {
            const formData = new FormData()
            formData.append('file', photo.raw)
            promises.push(CraigslistAPI.syndication_photos.create(propertyId, 'properties', formData))
          }
          for (const photo of photos.removed) {
            promises.push(CraigslistAPI.syndication_photos.delete(propertyId, 'properties', photo.id))
          }
          this.$delete(this.property, 'photos')
        }

        // Now upload the agreements (if they exists)
        if (this.property.agreements) {
          for (const agreement of this.property.agreements.added) {
            const formData = new FormData()
            formData.append('file', agreement.raw)
            promises.push(RooofAPI.agreements.create(propertyId, 'properties', formData))
          }
          for (const agreement of this.property.agreements.removed) {
            promises.push(RooofAPI.agreements.delete(propertyId, 'properties', agreement.id))
          }
          this.$delete(this.property, 'agreements')
        }

        // Update the Property and CraigslistProperty objects
        promises.push([
          await RooofAPI.properties.update(propertyId, this.property.property),
          await CraigslistAPI.properties.update(propertyId, this.property)
        ])
        await Promise.all(promises)

        this.$message({
          message: `Property ${this.property.property.name} updated`,
          type: 'success',
          duration: 3500
        })
        this.getProperty()
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Submit request to delete this property.
     */
    async deleteProperty () {
      this.loading = true
      try {
        await RooofAPI.properties.delete(this.$route.params.id)
        this.$message({
          message: `Property ${this.property.property.name} deleted.`,
          type: 'success',
          duration: 3500
        })
        this.$router.push({ name: 'CompanyOverview' })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  margin-bottom: 1em;
  h1 {
    font-size: 1.875rem;
    font-weight: 600;
    margin: 0;
  }
  p {
    margin: 0.375em 0;
  }
}
.button-icon {
  vertical-align: middle;
  fill: #fff;
  height: 14px;
  width: 14px;
}
.button-text {
  margin-left: 5px;
}
</style>
